<template>
  <b-row>
    <b-col lg="12" class="mb-1">
      <b-row>
        <b-col>
          <b-card-actions title="系统角色管理" @refresh="refreshStop()" ref="refreshCard">

            <b-row>
              <b-col lg="7" class="mb-1">
                <b-button-group>
                  <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1 @click="clearfrom()"><feather-icon
                      icon="PlusCircleIcon" /></b-button>
                  <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
                      icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button>
                  <b-button variant="primary" class="btn-icon" @click="deleteAllRows()"><feather-icon
                      icon="Trash2Icon" />&nbsp;<span class="align-middle">批量删除</span></b-button>
                </b-button-group>
              </b-col>
              <b-col lg="5" class="mb-1">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
                  <b-form-input placeholder="角色名称" v-model="search_name" />
                  <b-input-group-append><b-button variant="outline-primary"
                      @click="get_search()">检索</b-button></b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <b-table ref="selectableTable" @row-selected="onRowSelected" selectable select-mode="multi" class="tabledata"
              striped hover responsive="sm" :items="items" :fields="fields" sticky-header no-border-collapse>
              <template #cell(name)="data">
                <span class="text-nowrap">
                  {{ data.value }}
                </span>
              </template>
              <template #cell(menu)="data">
                <span style="width:400px;display:inline-block;">
                <b-badge variant="light-success" style="margin-right:2px;margin-bottom:2px" v-for="tag in data.item.menu.split(',')" :key="tag.index">{{tag}}</b-badge>
                </span>
              </template>
              <!-- 状态 -->
              <template #cell(flg)="data">
                <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.flg)}`" class="text-capitalize">
                  <span v-if="data.item.flg == 1">正常</span>
                  <span v-else>停用</span>
                </b-badge>
              </template>
              <!-- 设置 -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>

                  <b-dropdown-item @click="get_edit(data.item.id)" v-b-toggle.sidebar-1>
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">编辑</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="get_del(data.item.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">删除</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>


            </b-table>

            <b-pagination v-model="currentPage" :total-rows="rows" first-number last-number prev-class="prev-item"
              next-class="next-item" align="left" :per-page="perPage"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

 
                <b-sidebar id="sidebar-1" shadow bg-variant="white" right backdrop width="auto"   title="角色编辑">
                  <b-form >
                    <validation-observer ref="simpleRules">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="角色名称" label-for="h-first-name" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_name" rules="required">
                          <b-form-input v-model="FormData.name" id="h-first-name" placeholder="请输入角色名称" :state="errors.length > 0 ? false:null" />
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row>
                      <b-col cols="12">
                        <b-form-group label="排序" label-for="h-id" label-cols-md="4">
                          <validation-provider #default="{ errors }"  name="menu_name" rules="required">
                          <b-form-input v-model="FormData.ord" id="h-id" type="number" placeholder="排序" :state="errors.length > 0 ? false:null"/>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </b-row>
                      <b-row>
                      <b-col cols="12">
                        <b-form-group label="启用状态" label-for="h-number" label-cols-md="4">
                          <b-form-checkbox
                              checked="true"
                              class="custom-control-primary"
                              name="check-button"
                              switch
                              v-model="flg"
                            >
                              <span class="switch-icon-left">
                                <feather-icon icon="CheckIcon" />
                              </span>
                              <span class="switch-icon-right">
                                <feather-icon icon="XIcon" />
                              </span>
                            </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      </b-row>
                     <b-row class="mb-1">
                      <b-col cols="12">
                          <h6 class=" font-weight-bold mb-2">
                              选择栏目 
                            </h6>
                            <app-collapse>
                              <app-collapse-item :title="menu.label" v-for="menu in menus" :key="menu.id">
                                <draggable :list="menu.children" tag="ul" group="people"
                                  class="list-group list-group-flush">
                                  <b-list-group-item v-for="(listItem, index) in menu.children" :key="index" tag="li">
                                    <div class="d-flex">
                                      <b-form-checkbox v-model="selected_role" :value="listItem.id"></b-form-checkbox>
                                      <b-avatar size="sm">
                                        <span class="d-flex align-items-center">
                                          <feather-icon :icon="listItem.icon" size="14"/>
                                        </span>
                                      </b-avatar>
                                      <div class="ml-25">
                                        <b-card-text class="mb-0 font-weight-bold">
                                          {{ listItem.label }}
                                        </b-card-text>
                                      </div>
                                    </div>
                                  </b-list-group-item>
                                </draggable>
                              </app-collapse-item>
                            </app-collapse>
                      </b-col>
                      </b-row>
                     <b-row>

                      <b-col cols="12" style="text-align:center">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="primary" class="mr-1" @click="save()">保存</b-button> 
                      </b-col>
                    </b-row>
                    </validation-observer>
                  </b-form>
                </b-sidebar> 

          </b-card-actions>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BBadge, BCardText, BPagination, BButton, BPopover, BTable, BRow, BCol, BDropdown, BDropdownItem, BSidebar, VBToggle, BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroupAppend, BButtonGroup,
  BInputGroup, BInputGroupPrepend, BFormSelect
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { BListGroupItem, BAvatar } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BFormSelect,
    BBadge,
    BSidebar,
    VBToggle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    BCard,
    BCardText,
    BCardActions,
    BButton,
    BPopover,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    draggable,
    BListGroupItem,
    BAvatar,
    Prism,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroupAppend,
    BButtonGroup,
    BInputGroup,
    BInputGroupPrepend,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'ID', },
        { key: 'name', label: '菜单名称', },
        { key: 'ord', label: '排序', },
        { key: 'menu', label: '包含栏目', },
        { key: 'flg', label: '状态', },
        { key: 'actions', label: '设置', },
      ],
      items: [],
      table_height: 400,
      currentPage: 1,
      perPage: 20,
      rows: 0,
      selected_role:[],
      selected: [],
      select_all: 0,
      select_mode: "全选",
      nameFilter: '',
      roleFilter: '',
      planFilter: '',
      search_name: '',
      detailstData: {},
      editData: {},
      flg:true,

      FormData: {
        act: '',
        id: '',
        name: '',
        ord: '',
        menu: '',
        flg: '',

      },
      menus:[],
      listData: []
    }
  },
  created() {
    this.get_data()
    this.get_main_menu()
  },
  methods: {
    clearfrom(){
      this.selected_role=[]
      this.FormData= {
        act: '',
        id: '',
        name: '',
        ord: '',
        menu: '',
        flg: '',
      }
    },
    get_main_menu() {
      let dataObj = {
        act: "get_menus", 
      }
      this.$http.post('svr/admin_menu.php', dataObj)
        .then(res => {
          this.menus = res.data 
        })
    },
    get_search() {
      this.get_data()
    },
    getStause(val) {
      this.planFilter = val
    },
    onRowSelected(items) {
      this.selected = items
      if(items.length>0){
        this.showToast('success', '选中了' + (items.length) + '条数据!')
      }
    },
    selectAllRows() {
      if (this.select_all == 1) {
        this.clearSelected()
        return
      }
      this.$refs.selectableTable.selectAllRows()
      this.select_all = 1
      this.select_mode = "取消全选"
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.select_all = 0
      this.select_mode = "全选"
    },
    refreshStop() {
      this.currentPage = 1
      this.search_name = ""
      this.get_data()
    },
    set_delids(id) {
      let val = this.selected
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      ids = ids.join();
      if(id){
        ids = id;
      }
      return ids;
    },
    deleteAllRows(id) {
      let ids = this.set_delids(id)
      if (!this.selected.length > 0&&!id>0) {
        this.showToast('danger', '请先选择您要删除的数据!')
        return
      }
      this.$swal({
        title: '确定删除数据吗?',
        text: "此操作将不可逆,请谨慎操作",
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '我确定!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.del_data(ids)
        }
      })
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '系统提示',
          icon: 'BellIcon',
          text: text,
          variant,
        },
      })
    },

    get_data() {
      this.$nextTick(() => {
        this.$refs["refreshCard"].showLoading = true
      })
      let dataObj = {
        act: "lst",
        pge: this.currentPage,
        cnt: this.perPage,
        name: this.search_name ? this.search_name : ''
      }
      this.$http.post('svr/admin_role.php', dataObj)
        .then(res => {
          this.items = res.data.lst
          this.rows = res.data.cnt_all
          setTimeout(() => {
            this.$refs["refreshCard"].showLoading = false
          }, 300)
        })
    },
    del_data(ids) {
      let dataObj = {
        act: "del",
        id: ids,
      }
      this.$http.post('svr/admin_role.php', dataObj)
        .then(res => {
          this.showToast('success', '删除成功')
          this.get_data()
        })
    },
    get_del(val) {
      this.deleteAllRows(val)
    },
    get_edit(e) {
      let getdata = {
        act: 'get',
        id: e
      }
      this.$http.post('svr/admin_role.php', getdata).then(res => {
        console.log(res, 'res');
        if (res.code == 20000) {
          this.FormData = res.data
          this.selected_role = res.data.menu.split(',')
          if(this.FormData.flg==1){this.flg = true}else{this.flg = false}
          
          console.log(value, ' value.value');
          console.log(this.FormData, '编辑传递数据');
        } else {

        }
      })

    },
    save() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(this.flg){
            this.FormData.flg=1
          }else{
            this.FormData.flg=0
          }
          if (this.FormData.id == '') {
            this.FormData.act = 'add'
          } else {
            this.FormData.act = 'mod'
          }
          this.FormData.menu=JSON.stringify(this.selected_role)
          this.$http.post('svr/admin_role.php', this.FormData).then(res => {
              if (res.code == 20000) {
                this.showToast('success', '编辑成功!')
                this.get_data()
              }
          })
        }
     })
    },
    resolveUserStatusVariant(flg) {
      if (flg === '0') return 'warning'
      if (flg === '1') return 'success'
      return 'primary'
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.b-table-sticky-header {
  max-height: calc(100vh - 420px);
}

.list-group-item {
  transition: all 1s
}

.tabledata {
  border-radius: 3px;
}

.b-table-sticky-header {
  max-height: calc(100vh - 420px) !important;
}

.custom-select {
  padding: 0.438rem 0rem 0.438rem 0.6rem;
  width: 3.4rem;
}

.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}

.filter {
  margin-bottom: 2rem;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  /* padding: 10px 0; */
}

.custom-select {
  width: 100%;
}

.d-flex {
  align-items: center;
}



</style>

